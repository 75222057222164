import React, { useEffect, useState } from 'react';
import { Amplify, Auth, Hub, API } from 'aws-amplify';
import { Button, useTheme, View, Image, Text, Heading,
	 useAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import RingLoader from 'react-spinners/RingLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import '@aws-amplify/ui-react/styles.css';
import './App.css';


Amplify.configure(awsconfig);






const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large} className="vintra-header">

	{/*
      */}
      </View>
	   
    );
  },
};




const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: 'Enter your email',
    },
  },
  signUp: {
    password: {
      labelHidden: false,
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      labelHidden: false,
      label: 'Confirm Password:',
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: 'Enter your email:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user',
    },
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};



export default function App() {

  // init
  useEffect(() => {
    document.title = 'Vintra Demo Servers';
  });

  const [user, setUser] = useState(null);
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchdata = async function(){
    console.log('fetchdata');
    setLoading(true);
    const response = await API.get('Server', '/server');
    console.log(response);
    setServers(response);
    setLoading(false);
  };

  const switchServer = async function(id, state){
    const new_servers = [...servers];
    const server =  servers.filter((server) => server.id == id)[0];
    server.state.Name = '';
    server.state.Code = '';
    console.log(new_servers);
    setServers(new_servers);
    const response = await API.put('Server', `/server?id=${id}&state=${state}`);
    console.log('response', response);
  };


  useEffect(() =>  {
    if (user) {
      fetchdata();
    } else {

    }
  }, [user]);


  const sign_out_wrapper = function (sign_out_fn){
    setUser(null);
    sign_out_fn();
  }

  
  
  return (
    <>

      <header className="header">
        <Image className="vintra-logo"
               alt="Vintra logo"
               src="/vintra_logo.png"
        />

	<h2 className="vintra-title"> Demo Servers </h2>

	{user &&  <button className="sign-out" onClick={() => Auth.signOut()}>Sign Out</button>}
      </header>

    
    <Authenticator components={components} formFields={formFields} hideSignUp={true}> 
    
      {({ signOut, user }) =>  {
	setUser(user);
      }}
    </Authenticator>


      {user && (
	<div className="server-container">
 	  {loading
	   ?
	   <RingLoader color={'#FFFFFF'} isLoading={loading}
		       size={'20rem'} /> :
	   <ul>
	     {servers.map((server) =>
	       <li className="server-record" key={server.id}>
 		 <div className="server-name"><h3>{server.name}</h3>  </div>
 		 <div className="server-id"> {server.id}  </div>
 		 <div className="server-state"> <h4> {server.state.Name}</h4> </div>

		 <div className="server-button">
		   {(() => {
		     switch (server.state.Code) {
		     case(16):
		       return <button onClick={() => switchServer(server.id, 'off')}
				      className="switch-button"> Stop </button>;
		     case(80):
		       return <button onClick={() => switchServer(server.id, 'on')}
				      className="switch-button"> Start </button>;}
		     return <BeatLoader color={'#FFFFFF'} size={10} isLoading={loading}
					className="beatloader"/>;})()

		   }
		 </div>

		 
	       </li>)}
	   </ul> }
	   
	</div>
      ) }
    </>
  );
}


// function App() {
//   const [user, setUser] = useState(null);
//   const [servers, setServers] = useState([]);
//   const [loading, setLoading] = useState(false);

  
//   const fetchdata = async function(){
//     setLoading(true);
//     const response = await API.get('Server', '/server');
//     setServers(response);
//     setLoading(false);
//   };

//   const switchServer = async function(id, state){
//     const new_servers = [...servers];
//     const server =  servers.filter((server) => server.id == id)[0];
//     server.state.Name = '';
//     server.state.Code = '';
//     console.log(new_servers);
//     setServers(new_servers);
//     const response = await API.put('Server', `/server?id=${id}&state=${state}`);
//     console.log('response', response);
//   };


//   useEffect(() =>  {
//     if (user) {
//       fetchdata();
//     } else {

//     }
//   }, [user]);
  
//   useEffect(() => {
//     Hub.listen('auth', ({ payload: { event, data } }) => {
//       console.log('auth event');
//       console.log(event);
//       switch (event) {
//       case 'signIn':
//       case 'cognitoHostedUI':
//         getUser().then(userData => setUser(userData));
//         break;
//       case 'signOut':
//         setUser(null);
// 	Auth.federatedSignIn();
//         break;
//       case 'signIn_failure':
//       case 'cognitoHostedUI_failure':
//         console.log('Sign in failure', data);
// 	Auth.federatedSignIn();
//         break;
//       }
//     });

//     getUser().then(userData => setUser(userData));
//   }, []);

//   function getUser() {
//     return Auth.currentAuthenticatedUser()
//       .then(userData => userData)
//       .catch(() => console.log('Not signed in'));
//   }

  


  
//   return (
//     <div>

//       {user ? (
// 	<>
//           <button onClick={() => Auth.signOut()}>Sign Out</button>
// 	  {loading ?
// 	   <RingLoader color={'#282c34'} isLoading={loading}
// 		       size={150} /> :
// 	   <ul>
// 	     {servers.map((server) =>
// 	       <li className="server-record" key={server.id}>
// 		 <div className="server-name">{server.name}  </div>
// 		 <div className="server-id">{server.id}  </div>
// 		 <div className="server-state"> {server.state.Name} </div>
// 		 {server.state.Code == 16 &&
// 		  <button onClick={() => switchServer(server.id, 'off')}> Stop </button>  }
// 		 {server.state.Code == 80 &&
// 		  <button onClick={() => switchServer(server.id, 'on')}> Start </button>  }

// 	       </li>)}
// 	   </ul> }
// 	</>
//       ) : (
//         <button onClick={() => Auth.federatedSignIn()}>Federated Sign In</button>
//       )}
//     </div>
//   );
// }

// export default App;





// import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';
// import { API } from 'aws-amplify';
// import { useState, useEffect } from "react";
// import logo from './logo.svg';
// import './App.css';


// function App({ signOut, user }) {
//   const [servers, setServers] = useState([]);

//   useEffect(() => {
//     const fetchdata = async () => {
//       const response = await API.get('Servers', '/server');
//       setServers(response);
//       console.log('response', response);
//     };

//     fetchdata();
//   }, []);

//   return (
//     <>

//       <Heading level={1}>Hello {user.email}</Heading>
//       <Button onClick={signOut}>Sign out</Button>

//       <ul>

// 	{servers.map(server => <li> {server.name} </li>) } 
//       </ul>
//     </>
//   );
// }
